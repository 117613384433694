<template>
    <div class="row">
        <div class="col-12">
            <div class="card shadow-sm">
                <div class="card-body scroll-y">
                    <table class="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th class="w-25">{{ $t("organisation") }}</th>
                                <th class="w-25">{{ $t("plant") }}</th>
                                <th>{{ $t("price_per_month") }}</th>
                                <th>{{ $t("new_price_per_month") }}</th>
                                <th>{{ $t("price_valid_from") }}</th>
                                <th>{{ $t("new_plant_price") }}</th>
                                <th>{{ $t("new_price_valid_from") }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :class="getTrCssClass(plantPrice)" v-for="plantPrice in sortedPlants"
                                v-bind:key="plantPrice">
                                <td > {{ plantPrice.organisation_bez }}</td>
                                <td> {{ plantPrice.plant_bez }}</td>
                                <td > {{ plantPrice.price_per_month }}</td>
                                <td > {{ plantPrice.new_price_per_month }}</td>
                                <td > {{ plantPrice.new_price_at_date }}</td>
                                <td><input type="number" name="name" class="form-control"  v-model="plantPrice.new_price"></td>
                                <td><input type="date" name="name" class="form-control"  v-model="plantPrice.new_price_at_date_new"></td>
                                <td><button class="btn btn-success mb-0 ms-auto" @click="updatePriceForPlant(plantPrice)">{{ $t("update") }}</button></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th> {{ sum_orgas }}</th>
                                <th> {{ sum_plants }}</th>
                                <th> {{ sum_plants_prices }}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
   
</template>

<script>
import axios from 'axios'

export default {
    name: 'PlantPriceOverview',
    components: {
    },
    data() {
        return {
            plantPrices: []
        }
    },
    async mounted(){
        await this.getPrices()
    },
    updated(){

    },
    unmounted(){
        console.log('component unmounted')
        
    },
    methods: {
        getPrices(){
            console.log('entry updatePriceForPlant')

            axios
            .get(`/api/v1/plant_price_overview/`)
            .then(response => {
                this.plantPrices = response.data                  
            })
            .catch(error => {
                console.log(JSON.stringify(error))
            })
        },
        getTrCssClass(plantPrice) {
            if(plantPrice.organisation_bez != 'Anlagen Ohne Orga' && plantPrice.price_per_month < 1)
                return "table-danger"
            return ""
        },
        updatePriceForPlant(plantPrice){
            console.log('entry updatePriceForPlant')
            let plantPriceUpdate = structuredClone(plantPrice)
            if(plantPrice.new_price_at_date_new){
                plantPriceUpdate.new_price_at_date = plantPrice.new_price_at_date_new
                plantPriceUpdate.new_price_per_month = plantPrice.new_price
            }else{
                plantPriceUpdate.price_per_month = plantPrice.new_price
                plantPriceUpdate.new_price_at_date = null
                plantPriceUpdate.new_price_per_month = null
            }
            axios
            .patch(`/api/v1/plant_price_overview/${plantPriceUpdate.id}/`,plantPriceUpdate)
            .then(response => {
                console.log('price updated')
                this.getPrices()                    
            })
            .catch(error => {
                console.log(JSON.stringify(error))
            })
            console.log('exit updatePriceForPlant')
        }
    },
    computed:{
        sortedPlants(){
            if(this.plantPrices.length == 0)
                return []
            return this.plantPrices.sort((a,b) => {
                if(a.organisation_bez < b.organisation_bez){
                    return -1
                }
                if (a.organisation_bez > b.organisation_bez){
                    return 1
                }
                return 0
            })
        },
        sum_orgas(){
            const uniqueValues = new Set(this.plantPrices.map(obj => obj['organisation']));
            return 'Anzahl Organisationen: ' + uniqueValues.size
        },
        sum_plants(){
            return 'Anzahl Anlagen: ' + this.plantPrices.length
        },
        sum_plants_prices(){
            let sum_price = 0
            this.plantPrices.forEach(element => {
                sum_price += parseFloat(element.price_per_month)
            });
            return sum_price
        }
    }
}
</script>
